import * as React from "react"
import { Link } from "gatsby"

import Page from "src/components/Page"

const NotFoundPage: React.FC = () => (
  <Page>
    <h1>404: Page not found.</h1>
    <p>
      You've hit the void. <Link to="/">Go back.</Link>
    </p>
  </Page>
)

export default NotFoundPage
